// DirectoriesAutocomplete.js
import React from "react";
import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SET_NTFS_DIRECTORIES } from "../../../../redux/constants/constants";
import ImageTooltip from "../../../common/HelpIconWithTooltip";
import { directoryDescription, directoryExample } from "../../NTFS/ntfsConstants";
import { NTFS } from "../dataSourceConstants";
import { Typography } from "material-ui-core";

function DirectoriesAutocomplete({
  ntfsDirectories,
  classes,
  dataSourceData,
  dispatch,
  removeErrorByKey,
  error,
  handleAddNewDirectory,
  handleAutocompleteTags,
}) {
  const directoryNames = dataSourceData.directoryNames || [];
  const styledDescription = (
    <Typography>
      <span className={classes.tooltipHeading}>{directoryDescription}</span><br/>
      <span className={classes.tooltipExample}>{directoryExample}</span>
    </Typography>
  );
  return (
    <div className={classes.customDirectories}>
      <div className={classes.tooltipContainer}>
        <div>Directories</div>
        <ImageTooltip description={styledDescription} loc={NTFS} />
      </div>
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={ntfsDirectories}
          onChange={(event, newValue) => {
            const filteredNewValue = newValue.filter(
              (item) => !directoryNames.includes(item)
            );
            const updatedValue = [...directoryNames, ...filteredNewValue];
            dispatch({ type: SET_NTFS_DIRECTORIES, payload: updatedValue });
            removeErrorByKey("ntfsDirectories");
          }}
          renderTags={(value, getTagProps) =>
            handleAutocompleteTags(value, getTagProps, "Directories")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              onKeyDown={handleAddNewDirectory}
              onBlur={handleAddNewDirectory}
              fullWidth
              error={!!error.ntfsDirectories}
              helperText={error.ntfsDirectories}
              placeholder="Directories"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

export default DirectoriesAutocomplete;
