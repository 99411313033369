import { put, takeEvery, select, call } from "redux-saga/effects";
import { getAxiosHeaders, getService } from "../../library/RestAPI";
import {
  SET_GRAPH_NODES_LIST,
  GET_DATAMAP_NODES,
  SET_DATAMAP_DETAILS,
  SET_DATAMAP_LOADER,
  SET_DATAMAP_TOOLTIP_DETAILS,
  SET_DATAMAP_TOOLTIP_LOADER,
  SET_DATAMAP_MULTI_TOOLTIP_LOADER,
  ENABLE_NODE_TOOLTIP,
  GET_DATAMAP_NODE_TOOLTIP,
  SET_DATAMAP_MULTI_TOOLTIP_DATA,
  SET_TOOL_TIP_DATA,
  SET_LOADER,
  SET_PUBLIC_DB_ACCESS_DETAILS,
} from "../constants/constants.js";
import { getCubejsApi } from "../../../src/components/common/Dashboard/cubejs-util";

import {
  getNodeProperties,
  getNodePositions,
  getPublicDBAccessDetails
} from "../../components/shared/dataMapUtilities";

import {dataMapRender} from "../../controllers/dataMapController";

function* processDataMapResponse(dataMapNodes, filterId) {
  // generate edges and nodes and return
  let ReducerData = yield select();
  const { nodePositions } = ReducerData.nodePositions;

  const nodesObj = dataMapRender(dataMapNodes, nodePositions);
  getNodeProperties(nodesObj);
  getNodePositions(nodesObj, nodePositions);
  const publicDBAccessDetails = getPublicDBAccessDetails(nodesObj);
    yield put({
      type: SET_GRAPH_NODES_LIST,
      payload: nodesObj,
      filterId: filterId,
    });
    yield put({
      type: SET_PUBLIC_DB_ACCESS_DETAILS,
      payload: publicDBAccessDetails
    })
}

//  To get cube data map data
async function getCubeDataMapData(queryString) {
  const cubejsApi = getCubejsApi();
  const resultSet = await cubejsApi.load(queryString);
  const response = resultSet.rawData();
  if (response && response.length > 0) {
    const nodeIds = response.map(function (item) {
      return item["NodesOfApplication.nodeId"];
    });
    return nodeIds;
  }
}

function* getNodeTooltipData(action) {
  const nodeId = action.payload.node.id;
  const options = {
    method: "GET",
    url: `${GET_DATAMAP_NODE_TOOLTIP}/${nodeId}`,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: SET_DATAMAP_MULTI_TOOLTIP_DATA,
        payload: {data: res.data, selectedHoverNode: action.payload.node, loaderStatus: false, posiCoord: action.payload.posiCoord},
      });
    }
  }catch(error){
    yield put({
      type: SET_DATAMAP_TOOLTIP_LOADER,
      payload: {selectedHoverNode: action.payload.node, loaderStatus: false, posiCoord: action.payload.posiCoord },
    });
  }
}

function* getNodesList(action) {
  const nodeIds = yield call(getCubeDataMapData, action.payload.queryString);
  yield* getDataMapInfo(nodeIds, action.payload.filterId);
}

function* enableNodeTooltip(payload) {
  yield put({
    type: SET_DATAMAP_MULTI_TOOLTIP_LOADER,
    payload: {loaderStatus: true, ...payload.action},
  });
  yield* getNodeTooltipData({payload: {node:payload.action.selectedHoverNode, posiCoord: payload.action.posiCoord}});
}

function* getDataMapInfo(action, filterId) {
  if (action && action.length) {
    const options = {
      method: "POST",
      url: `${GET_DATAMAP_NODES}`,
      headers: getAxiosHeaders(true),
      data: { nodeIds: action },
    };
    try {
      const res = yield getService(options);
      if (res && res.status === 200) {
        yield put({
          type: SET_DATAMAP_DETAILS,
          payload: yield processDataMapResponse(res.data, filterId),
        });
      }
      yield put({
        type: SET_DATAMAP_LOADER,
        payload: false,
      });
    } catch (error) {
      yield put({
        type: SET_DATAMAP_LOADER,
        payload: false,
      });
    }
  } else {
    // nothing to load
    yield put({
      type: SET_DATAMAP_LOADER,
      payload: false,
    });
  }
}

function* getTooltipData(action) {
  yield put({
    type: SET_LOADER,
    payload: true,
  });
  const nodeId = action.payload;
  const options = {
    method: 'GET',
    url: `${GET_DATAMAP_NODE_TOOLTIP}/${nodeId}`,
    headers: getAxiosHeaders(true),
  };
  try {
    const res = yield getService(options);
    if (res && res.status === 200) {
      yield put({
        type: SET_TOOL_TIP_DATA,
        payload: res.data,
      });
    }
  } catch (error) {
    yield put({
      type: SET_LOADER,
      payload: false,
    });
  }
}

function* dataMapSaga() {
  yield takeEvery(ENABLE_NODE_TOOLTIP, enableNodeTooltip)
  yield takeEvery("GET_NODES_LIST", getNodesList);
  yield takeEvery("GET_DATAMAP_TOOLTIP_DATA", getTooltipData)
}

export default dataMapSaga;
