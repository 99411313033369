// ProcessAutocomplete.js
import React from "react";
import { TextField, FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SET_NTFS_PROPOSED } from "../../../../redux/constants/constants";
import { processDescription, processExample } from "../../NTFS/ntfsConstants";
import { NTFS } from "../dataSourceConstants";
import ImageTooltip from "../../../common/HelpIconWithTooltip";
import { Typography } from "material-ui-core";

function ProcessAutocomplete({
  ntfsProcessNames,
  dataSourceData,
  classes,
  dispatch,
  removeErrorByKey,
  error,
  handleAddNewProposed,
  handleAutocompleteTags,
}) {
  const processNames = dataSourceData.processNames || [];
  const styledDescription = (
    <Typography>
      <span className={classes.tooltipHeading}>{processDescription}</span><br/>
      <span className={classes.tooltipExample}>{processExample}</span>
    </Typography>
  );
  return (
    <div className={classes.customDirectories}>
      <div className={classes.tooltipContainer}>
        <div>Process Names</div>
        <ImageTooltip description={styledDescription} loc={NTFS} />
      </div>
      <FormControl variant="outlined" fullWidth>
        <Autocomplete
          multiple
          freeSolo
          options={[]}
          value={ntfsProcessNames}
          onChange={(event, newValue) => {
            const filteredNewValue = newValue.filter(
              (item) => !processNames.includes(item)
            );
            const updatedValue = [...processNames, ...filteredNewValue];
            dispatch({ type: SET_NTFS_PROPOSED, payload: updatedValue });
            removeErrorByKey("ntfsProcessNames");
          }}
          renderTags={(value, getTagProps) =>
            handleAutocompleteTags(value, getTagProps, "processNames")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              onKeyDown={handleAddNewProposed}
              onBlur={handleAddNewProposed}
              fullWidth
              error={!!error.ntfsProcessNames}
              helperText={error.ntfsProcessNames}
              placeholder="Process Names"
            />
          )}
        />
      </FormControl>
    </div>
  );
}

export default ProcessAutocomplete;
