import {
  ALL_DATAMAP_NODES_BY_CUBE_NODES_REQUEST,
  SET_DATAMAP_DETAILS,
  SET_GRAPH_NODES_LIST,
  SET_DATAMAP_USER_DETAILS,
  SET_DATAMAP_LOADER,
  CLEAR_DATA_MAP,
  SET_PUBLIC_DB_ACCESS_DETAILS,
} from "../constants/constants";

const initialState = {
  requestBody_AllDatamapNodes: {},
  loader: false,
  nodesList: {},
  filterId: '',
  dataMapUserNodes: [],
  graphNodesList: {
    apiSucc: false,
    data: {
      nodes: [],
      edges: [],
    },
  },
  dataMapFilePopUp: false,
  dataMapFilePopUpData: {},
  publicDBAccessDetails: []
};

export const dataMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_DATAMAP_NODES_BY_CUBE_NODES_REQUEST: {
      return {
        ...state,
        requestBody_AllDatamapNodes: action.payload,
      };
    }
    case SET_DATAMAP_DETAILS: {
      return {
        ...state,
        dataMapNodes: action.payload
      }
    }
    case SET_DATAMAP_USER_DETAILS: {
      return {
        ...state,
        dataMapUserNodes: action.payload,
        size: action.size,
        type: action.criteriaType
      }
    }
    case SET_GRAPH_NODES_LIST: {
      return {
        ...state,
        graphNodesList: action.payload,
        filterId: action.filterId
      }
    }
    case SET_DATAMAP_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_PUBLIC_DB_ACCESS_DETAILS: {
      return {
        ...state,
        publicDBAccessDetails: action.payload
      }
    }
    case CLEAR_DATA_MAP: {
      return {
        ...state,
        filterId: '',
        dataMapUserNodes: [],
        graphNodesList: {
          apiSucc: false,
          data: {
            nodes: [],
            edges: [],
          },
        }
      };
    }
    case "OPEN_FILE_POP_UP": {
      return {
        ...state,
        dataMapFilePopUp: true,
        dataMapFilePopUpData: action.payload,
      };
    }
    case "CLOSE_FILE_POP_UP": {
      return {
        ...state,
        dataMapFilePopUp: false,
        dataMapFilePopUpData: {},
      };
    }
    default:
      return state;
  }
};
