import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GraphView from "../shared/GraphView";
import { cubeQueryGen } from "../common/utilities";
import { getCubejsApi } from "../../../src/components/common/Dashboard/cubejs-util";
import { dataMapUsersRender } from "../../controllers/dataMapController";
import { getNodeIds, filterDataMapNodes } from "../shared/dataMapUtilities";
import {
  SET_DATAMAP_LOADER,
  GET_USER_NODES_LIST,
  SET_GRAPH_NODES_LIST,
  GET_NODES_LIST,
  FILTER_ID,
  NODE_POSITIONS
} from "../../../src/redux/constants/constants";
import {
  getNodePositions
} from "../../components/shared/dataMapUtilities";
import Page_Loader from "../../assets/vizr_images/page_loader.gif";
import { findIndex } from "lodash";

export default function ShowDataMap(props) {
  const { handleSelectedNode, bottomTrayEnableEve, showUsers } = props;
  const cubejsApi = getCubejsApi();
  const dispatch = useDispatch();
  const graphNodesList = useSelector(
    (state) => state.dataMapReducer.graphNodesList
  );
  const filterId = useSelector(
    (state) => state.dataMapReducer.filterId
  );
  const dataMapUserNodes = useSelector(
    (state) => state.dataMapReducer.dataMapUserNodes
  );
  const nodePositions = useSelector(
    (state) => state.nodePositions.nodePositions
  );
  const loader = useSelector((state) => state.dataMapReducer.loader);
  const publicDBAccessDetails = useSelector((state) => state.dataMapReducer.publicDBAccessDetails);
  useEffect(() => {
    const dimensionType = ["nodeId", "nodeName", "nodeType"];
    const queryString = cubeQueryGen("NodesOfApplication", dimensionType, []);
    dispatch({
      type: SET_DATAMAP_LOADER,
      payload: true,
    });

    //To get NodeIds from Cube Js Api
    dispatch({
      type: GET_NODES_LIST,
      payload: {
        cubejsApi: cubejsApi,
        queryString: queryString,
      },
    });
  }, []);
  // To get the all user data nodes
  useEffect(() => {
      if (showUsers && !dataMapUserNodes.data) {
        let nodeIds = getNodeIds(graphNodesList);
        dispatch({
          type: GET_USER_NODES_LIST,
          payload: { nodeId: nodeIds },
        });
      } else if (
        showUsers &&
        graphNodesList &&
        graphNodesList.data &&
        dataMapUserNodes &&
        dataMapUserNodes.data
      ) {
        const dataMapUsers = dataMapUsersRender(dataMapUserNodes);
        let userNodes = [];
        let userEdges = [...new Map(dataMapUsers.data.edges.map(item => [item.from, item])).values()];
        getNodePositions(dataMapUsers, nodePositions);
        userEdges.forEach((dataNode) => {
          const earlierIdx = findIndex(graphNodesList.data.nodes, (o) => { return o.id === dataNode.to });
          if (earlierIdx !== -1) {
            userNodes = [...new Map(dataMapUsers.data.nodes.map(item => [item.id, item])).values()];
          }
        });
        dispatch({
          type: SET_GRAPH_NODES_LIST,
          payload: {
            apiSucc: true,
            data: {
              nodes: [...graphNodesList.data.nodes, ...userNodes],
              edges: [...graphNodesList.data.edges, ...userEdges],
            },
          },
        });
      } else if (!showUsers && graphNodesList.data) {
        // To get the data map nodes after uncheck
        let dataMapNodes = filterDataMapNodes(graphNodesList);
        dispatch({
          type: SET_GRAPH_NODES_LIST,
          payload: {
            apiSucc: true,
            data: {
              nodes: dataMapNodes,
              edges: [graphNodesList.data.edges],
            },
          },
        });
      }
  }, [showUsers, dataMapUserNodes])

  // To get the filtered user data nodes
  useEffect(() => {
    if (showUsers && graphNodesList.data.nodes.length && filterId === FILTER_ID) {
      let nodeIds = getNodeIds(graphNodesList);
      dispatch({
        type: GET_USER_NODES_LIST,
        payload: { nodeId: nodeIds },
      });
    }
  }, [filterId])

  const updateStoreForPositions = (dataPositions) => {
    dispatch({ type: NODE_POSITIONS, data: dataPositions });
  };
  return (
    // draw data map
    <>
      {graphNodesList && graphNodesList.apiSucc && graphNodesList.data.nodes.length ? (
        <div className="graph-nodes">
          {(graphNodesList && graphNodesList.data.nodes.length) || showUsers ? (
            <GraphView
              handleSelectedNode={handleSelectedNode}
              updateStoreForPositions={updateStoreForPositions}
              graphNodesList={graphNodesList.data.nodes}
              graphEdgesList={graphNodesList.data.edges}
              bottomTrayEnableEve={bottomTrayEnableEve}
              publicDBAccess ={publicDBAccessDetails}
            />
          ) : (
            <div className="no-app-nodes">
              No Nodes available for the selected date-range / application.
            </div>
          )}
        </div>
      ) : (
        loader ? (
          <div className="global-loader-holder">
            <img src={Page_Loader} alt="_Loader" className="loader" />
          </div>
        ) : (
          <div className="no-app-nodes">
            No Nodes available for the selected date-range / application.
          </div>
        )
      )}
    </>
  );
}
