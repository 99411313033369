import React from "react";
import GraphViewVis from "./GraphViewVis";

export default function GraphView(props) {
  const { handleSelectedNode, graphNodesList,bottomTrayEnableEve, publicDBAccess } = props;
  console.log(graphNodesList, "graphNodesList");
  return (
    <div style={{ marginLeft: "200px" }}>
      <GraphViewVis
        handleSelectedNode={handleSelectedNode}
        graphNodesList={props.graphNodesList}
        graphEdgesList={props.graphEdgesList}
        updateStoreForPositions={props.updateStoreForPositions}
        bottomTrayEnableEve={bottomTrayEnableEve}
        selectedApp={props.selectedApp}
        publicDBAccess={publicDBAccess}
      />
    </div>
  );
}
